import request from '@/utils/request.js'

export function getHomePageMsg(params) {
    return request({
        url:'/api/amazon/instantSales/getHomePageMsg',
        method:'get',
        params
    });
}

export function barGraph(params) {
    return request({
        url:'/api/amazon/instantSales/getColumnChartByField',
        method:'get',
        params
    });
}

export function pageInit() {
    return request({
        url:'/api/amazon/instantSales/pageInit',
        method:'get'
    });
}

export function listPage(params) {
    return request({
        url:'/api/amazon/asin/productAnalysisListPage',
        method:'get',
        params
    });
}

