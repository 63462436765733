<template>
  <div>
      <el-card style="width:100%;height:570px">
        <slot></slot>
        <div ref='info' style="width:100%;height:500px;margin:auto"></div>
      </el-card>
  </div>
</template>

<script>
export default {
    props:['barInfo'],
    data(){
        return {
            
        }
    },
    mounted(){
        console.log(this.barInfo);
        this.getInfo()
    },
    methods:{
        getInfo(){
            // const option={
            //     color:['#3894FF','#68BBC4','#58A55C'],
            //     legend: {},
            //     grid: {
            //         left: '1%',
            //         right: '0.9%',
            //         bottom: '2%',
            //         containLabel: true
            //     },
            //     tooltip: {
            //         trigger:'axis'
            //     },
            //     dataset: {
            //         source: [
            //             ['product', '今日', '昨日', '上周'],
            //             ['Matcha Latte', 43.3, 85.8, this.a],
            //             ['Milk Tea', 83.1, 73.4, this.a],
            //             ['Cheese Cocoa', 86.4, 65.2, this.a],
            //             ['Walnut Brown', 72.4, 53.9, this.a],
            //             ['Walnut Browni', 72.4, 53.9, 39.1],
            //             ['Walnut Br', 72.4, this.a, 39.1],
            //             ['Walnut Bro', 32.4, 53.9, 39.1],
            //             ['Walnu', 72.4, 53.9, 29.1],
            //             ['Walny', 72.4, this.a, 39.1],
            //             ['Walnf', 72.4, 73.9, 99.1],
            //             ['Walnx', this.a, 53.9, 39.1],
            //             ['Walnv', 72.4, 53.9, 39.1],
            //             ['Walnb', 72.4, this.a, 39.1]
            //         ]
            //     },
            //     xAxis: {type: 'category'},
            //     yAxis: {},
            //     // Declare several bar series, each will be mapped
            //     // to a column of dataset.source by default.
            //     series: [
            //         {type: 'bar',barWidth:'18',barGap: '0%'},
            //         {type: 'bar',barWidth:'18',barGap: '0%'},
            //         {type: 'bar',barWidth:'18',barGap: '0%'}
            //     ]
            // };
           const option = {
                grid: {
                    left: '1%',
                    right: '0.9%',
                    bottom: '2%',
                    containLabel: true
                },
                color:['#4ACA6D','#FFC22F','#4E6EF2'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    },
                    // backgroundColor:'red'
                },
                toolbox: {
                    feature: {
                        // dataView: {show: true, readOnly: false},
                        magicType: {show: true, type: ['line', 'bar']},
                        // restore: {show: true},
                        // saveAsImage: {show: true}
                    }
                },
                legend: {
                    data: ['今日', '昨日', '上周']
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00'],
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        // name: '水量',
                        min: 0,
                        // max: 250,
                        // interval: 50,
                        // axisLabel: {
                        //     formatter: '{value} ml'
                        // }
                    },
                    // {
                    //     type: 'value',
                    //     name: '温度',
                    //     min: 0,
                    //     max: 25,
                    //     interval: 5,
                    //     axisLabel: {
                    //         formatter: '{value} °C'
                    //     }
                    // }
                ],
                series: [
                    {
                        name: '今日',
                        type: 'line',
                        data: this.barInfo.today,
                        barWidth:'15',
                        barGap: '0%',
                        smooth: true,
                    },
                    {
                        name: '昨日',
                        type: 'line',
                        data: this.barInfo.yesterday,
                        barWidth:'15',
                        barGap: '0%',
                        smooth: true,
                    },
                    {
                        name: '上周',
                        type: 'line',
                        data:this.barInfo.lastweek,
                        barWidth:'15',
                        barGap: '0%',
                        smooth: true,
                    },
                ]
            };
            this.$echarts.init(this.$refs.info).setOption(option)
        }
    }
}
</script>

<style>

</style>